<template>
    <main>

        <section>
            <div class="text-container">
                <h1>Contact</h1>
                <div class="contact-naam openingstijden">
                    <p class="extra-bold">Openingstijden</p>
                    <small>
                        <p>Dinsdag t/m vrijdag</p>
                    </small>
                    <small>
                        <p>09:00 - 17:00</p>
                    </small>
                </div>
                <div class="contact-naam openingstijden">
                    <p class="bold">Hairstyle By Tess</p>
                    <small>
                        <p>Therese Kerssens</p>
                    </small>
                </div>
                <div class="contact-naam">
                    <p class="bold">KvK nummer</p>
                    <small>
                        <p>87044404</p>
                    </small>
                </div>

                <div class="contact-informatie">
                    <a class="" href="tel:+31-6-441-170-05">+31 6 441 170 05</a>
                    <a class="" href="mailto: info@hairstylebytess.nl">info@hairstylebytess.nl</a>
                    <small><a href="https://www.kyle-bot.com" target="_blank">
                            <p>© HAIRSTYLE BY TESS | MADE BY KYLE BOT </p>
                        </a></small>
                </div>
            </div>
        </section>

        <section class="contact-cta">
            <div>
                <h2>Neem contact op</h2>
                <a href="https://wa.me/31644117005" target="_blank" class="cta">Whatsapp</a>
                <a href="https://instagram.com/hairstylebytess" target="_blank" class="cta">Instagram</a>
                <a href="tel:+31-6-441-170-05" target=" _blank" class="cta">Telefoon</a>
                <a href="mailto: info@hairstylebytess.nl" target=" _blank" class="cta">Mail</a>
            </div>
        </section>
    </main>
</template>

<script>
import * as main from '../../assets/js/main.js';
export default {
    name: 'Contact',
    mounted() {
        main.scrollTo()
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
.openingstijden {
    margin-bottom: 30px;
}
main {
    padding: 0;
    display: grid;
    grid-template-columns: 40% 60%;
}

section {
    margin-bottom: 1px;
    height: 90vh;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;

    h2,h1 {
        margin-top: 0;
        font-size: $header-m;
    }

    >div {
        min-height: 50%;
    }

    .contact-info {
        min-height: 70%;
    }

    h2,h1 {
        margin-bottom: 50px;
    }

    @media (max-width: 600px) {
        flex-direction: column;

        .contact-cta {
            margin-left: 0;
            margin-top: 15%;

            a {
                margin-bottom: 40px;
            }
        }

        .contact-informatie {
            p {
                margin-top: 20px;
            }
        }
    }
}

.text-container {
    transform: translateX(16.6667%);

    p,
    a {
        font-weight: 300;
        font-family: 'Oswald';
    }
}

.contact-cta {
    background: $bg-gray;
    
    // justify-content: center;
    // align-items: center;
    background: $bg-gray;
    min-height: 70%;
    div {
        display: flex;
            flex-direction: column;
    }
    a {
        font-size: 26px;
    }
}

.contact-informatie {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
        color: $txt-color;
        line-height: 1.5;
        margin-right: auto;
    }

    p {
        margin-top: 100px;
    }
}

@media (max-width: 800px) {
    main {
        display: flex;
        flex-direction: column;
            section {
            padding: 1rem 8% 1rem 8%;
            height: auto;
            align-items: flex-start;
        }

        .text-container {
            margin-top: 3rem;
            transform: translateX(0);
        }
        .contact-cta {
            padding-bottom: 10vh;
        }
        h2,h1 {
            font-size: $header-sm;
        }
    }
}
</style>
