<template>
    <section class="home-info">
        <article>
            <div class="text-container">
                <h2>Hairstyle by tess</h2>
                <p>Welkom bij Hairstyle by Tess.
                    Jouw kapsalon in Wormer voor een prachtig kapsel, een professioneel advies of
                    haarproducten. Ik werk uitsluitend met topmerken als Goldwell, Maxxelle, Brand 5 en Seipuntozero. In mijn salon staat
                    persoonlijk contact voorop en bespreek graag onder het genot van een kop koffie of thee jouw haarwensen. Kleuren,
                    stylen, permanenten, speciale wensen of gelegenheden behoren allemaal tot de mogelijkheden. Bij mij ga je tevreden met
                    je nieuwe kapsel de deur uit. Zie ik Jou bij Hairstyle by Tess?</p>
                <router-link to="/about" class="cta"><span>Meer over mij</span></router-link>
            </div>

            <div class="model">
                <picture>
                    <source srcset="img/lexi.webp" type="image/webp" loading=lazy>
                    <source srcset="img/lexi.png" type="image/png" loading=lazy>
                    <img src="img/lexi.png" alt="haarmodel" loading=lazy>
                </picture>
            </div>
        </article>

        <article>
            <div class="model">
                <picture>
                    <source srcset="img/model_1.webp" type="image/webp" loading=lazy>
                    <source srcset="img/model_1.png" type="image/png" loading=lazy>
                    <img src="img/model_1.png" alt="haarmodel" loading=lazy>
                </picture>
            </div>

            <div class="text-container">
                <h2>Kleuren en producten</h2>
                <p>Ik help je graag met kleuradviezen voor een kleur die bij jou past. Ik werk bij haarverven of een haarkleuring
                uitsluitend met Goldwell. Ook voor het leveren van (duurzame) haarproducten, om je haar in topconditie te houden kun je
                bij mij terecht.</p>
                <router-link to="/about" class="cta"><span>Meer over mij</span></router-link>
            </div>
        </article>

        <article>
            <div class="text-container">
                <h2>Modellen</h2>
                <p>Vrouwen, mannen en kinderen…. Iedereen is van harte welkom bij Hairstyle by Tess. Lang haar, stijl haar, krullend haar,
                overal weet ik raad mee. Voor een klassiek of trendy kapsel, of voor een deskundig advies ben je bij mij aan het goede
                adres.</p>
                <router-link to="/about" class="cta"><span>Meer over mij</span></router-link>
            </div>

            <div class="scissor">
                <picture>
                    <source srcset="img/schaar_tess.webp" type="image/webp" loading=lazy>
                    <source srcset="img/schaar_tess.png" type="image/png" loading=lazy>
                    <img src="img/schaar_tess.png" alt="schaar" loading=lazy>
                </picture>
            </div>
        </article>

        <article class="map-container">
            <BaseMap />

            <div class="text-container">
                <h2>Afspraak maken</h2>
                <p>Maak nu een afspraak bij mij, neem contact op.</p>
                <router-link to="/contact" class="cta"><span>Afspraak maken</span></router-link>
            </div>
        </article>
    </section>
</template>

<script>
import BaseMap from './BaseMap.vue'
import * as main from '../assets/js/main.js';
export default {
    components: {
        BaseMap,
    },
    mounted() {
        const navimg = document.querySelector(".scissor img");
        function scrollScissor() {
            window.onscroll = function () { scrollRotate() };

            function scrollRotate() {
                
                navimg.style.transform = "rotate(" + (window.pageYOffset / 20) + "deg)"
            }
        }
        scrollScissor()

        main.homeInfo()
        main.scrollTo()
    }
}
</script>

<style lang="scss" scoped>




@import "../assets/scss/_variables.scss";
.map-container{
    position: relative;
    min-height: 40vh;
    display:flex;
    .text-container {
        transform: translateX(16.66667%) !important;
    }
   
}
.home-info {
    article {
        display: flex;
        align-items: center;
        margin-bottom: 15rem;
        
        picture img {
            max-width: 100%;
            // height: 750px;
        }
    }
        .text-container {
            z-index: 2;
            max-width: calc(100% / 3);
            transform: translateX(16.66667%);
            &:nth-of-type(even) {
                transform: translateX(-16.66667%);
                @media (min-width: 1600px) {
                        transform: translateX(-40%);
                    }
            }
            p {
                    line-height: 2;
                    margin-bottom: 2rem;
                }
        }

}

.model {
    width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: $bg-gray;
        padding: 6rem 0 0 0;
        min-height: 700px;
        
            img {
                min-height: 700px;
            }
}
.scissor {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg-gray;
    // padding: 0 0 0 0;
    min-height: 700px;
    picture {
        // display: inline-block;
        img {
                max-height: 500px;
                @media (min-width: 1600px) {
                        // max-width: 66.66%;
                        max-height: 800px;
                    }
            }
    }
}

.home-info {
    @media (max-width: 800px) {
        article {
            margin: 0;
            flex-direction: column;
            &:nth-of-type(odd) {
                    flex-direction: column-reverse;
                }

                .model {
                    width: 100vw;
                    min-height: 300px;
                    img {
                    height: 300px;
                    min-height: 300px;
                    }
                }
        }
        .text-container {
            max-width: 100%;
            transform: translateX(0%) translateY(-20%) !important;

        }

        .scissor{
            width: 100vw;
            min-height: 300px;
            img {
                z-index: -1;
                max-height: 120px;
            }
        }
    }
}


</style>
