export function hero() {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger);

    let hero = [".hero h1", ".hero a", ".hero .down-arrow"]
    let heroImg = ".hero img"

    ScrollTrigger.batch(hero, {
        onEnter: elements => {
            gsap.fromTo(elements, {
                autoAlpha: 0,
                x: -50
            }, {
                duration: 0.3,
                autoAlpha: 1,
                x: 0,
                stagger: 0.2,
                delay: 0.5,
            });
        },
        once: true
    });

    const anim = gsap.fromTo(heroImg, { autoAlpha: 0, x: 50 }, { duration: 0.3, delay: 0.6, autoAlpha: 1, x: 0 });
    ScrollTrigger.create({
        trigger: heroImg,
        animation: anim,
        toggleActions: 'play none none none',
        once: true,
    });
}

export function homeInfo() {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger);

    const articlesOdd = gsap.utils.toArray('article:nth-child(odd)');
    const articlesEven = gsap.utils.toArray('article:nth-child(even)');

    articlesOdd.forEach((article, i) => {
        const anim = gsap.fromTo(article, { autoAlpha: 0, x: -50 }, { duration: 0.3, autoAlpha: 1, x: 0 });
        ScrollTrigger.create({
            trigger: article,
            start: "top center+=200px",
            animation: anim,
            toggleActions: 'play none none none',
            once: true,
        });
    });

    articlesEven.forEach((article, i) => {
        const anim = gsap.fromTo(article, { autoAlpha: 0, x: 50 }, { duration: 0.3, autoAlpha: 1, x: 0 });
        ScrollTrigger.create({
            trigger: article,
            start: "top center+=200px",
            animation: anim,
            toggleActions: 'play none none none',
            once: true,
        });
    });
}

export function priceTable() {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger);

    const h1 = ".price-table h1";
    const table = ".price-table table";

    const anim = gsap.fromTo(h1, { autoAlpha: 0, x: -50 }, { duration: 0.3, autoAlpha: 1, x: 0, delay: 0.5, });
    ScrollTrigger.create({
        trigger: h1,
        start: "top center+=200px",
        animation: anim,
        toggleActions: 'play none none none',
        once: true,
    });

    const anim2 = gsap.fromTo(table, { autoAlpha: 0, x: 50 }, { duration: 0.3, autoAlpha: 1, x: 0, delay: 0.5, });
    ScrollTrigger.create({
        trigger: table,
        start: "top center+=200px",
        animation: anim,
        toggleActions: 'play none none none',
        once: true,
    });
}

export function scrollTo() {
    let a = document.querySelectorAll('a')

    a.forEach(el => {
        el.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        })
    })
}