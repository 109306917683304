<template>
    <footer>
        <div class="footer-info">
            <h2>Contact</h2>
            <div class="contact-naam openingstijden">
                <p class="extra-bold">Openingstijden</p>
                <small>
                    <p>Dinsdag t/m vrijdag</p>
                </small>
                <small>
                    <p>09:00 - 17:00</p>
                </small>
            </div>
            <div class="contact-naam openingstijden">
                <p class="bold">Hairstyle By Tess</p>
                <small>
                    <p>Therese Kerssens</p>
                </small>
            </div>

            <div class="contact-naam">
                <p class="bold">KvK nummer</p>
                <small>
                    <p>87044404</p>
                </small>
            </div>

            <div class="contact-informatie">
                <a class="" href="tel:+31-6-441-170-05">+31 6 441 170 05</a>
                <a href="mailto: info@hairstylebytess.nl">info@hairstylebytess.nl</a>
                <small>
                    <router-link to="kyle" target="_blank">
                        <p>© HAIRSTYLE BY TESS | MADE BY KYLE BOT </p>
                    </router-link>
                </small>
            </div>
        </div>

        <div class="footer-cta">
            <h2>Neem contact op</h2>
            <router-link to="/contact" class="cta">Contact</router-link>
        </div>
    </footer>
</template>

<script>

export default {
    methods:{
        redirect(){
            window.location.href ="www.kyle-bot.com";
        }
    }
}
</script>

<style lang="scss" scoped>


@import "../assets/scss/_variables.scss";
.openingstijden {
    margin-bottom: 30px;
}
footer {
    padding-top: 10vh;
    padding-bottom: 10vh;
    background: $bg-gray;
    display: flex;
    line-height: 1;

    .footer-cta {
        margin-left: 15%;
    }

    h2 {
        margin-bottom: 50px;
    }

    @media (max-width: 600px) {
        flex-direction: column;

        .footer-cta {
            margin-left: 0;
            margin-top: 15%;

            a {
                margin-bottom: 40px;
            }
        }
        h2 {
            font-size: $header-sm;
        }
        .contact-informatie {
            p {
                margin-top: 20px;
            }
        }
    }
}

.footer-info {

    p,
    a {
        font-weight: 300;
        font-family: 'Oswald';
    }
}

.contact-informatie {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
        color: $txt-color;
        line-height: 1.5;
        margin-right: auto;
    }

    p {
        margin-top: 100px;
    }

}
</style>
