<template>
  <main>
    <Hero />
    <AboutInfo />
  </main>
</template>

<script>
import Hero from '../../components/HeroAbout.vue'
import AboutInfo from '../../components/AboutInfo.vue'
export default {
  name: 'About',
  components: {
    Hero,
    AboutInfo
  }
}
</script>

<style lang="scss">



</style>
