<template>
    <section class="home-info">
        <article>
            <div class="text-container">
                <h2>Over mij</h2>
                <p>Hoi ik ben Therese, ook wel Tess genoemd.
                    Woon in Wormer met mijn man, zoon en hond Lexi.
                    Vanaf mijn 16de jaar werkzaam in het Kappersvak.
                    Inmiddels een kleine 40 jaar ervaring op het gebied van persoonlijk haaradvies, stylen, kleuren
                    etc..
                    Ik ben bij enkele grote kapsalons werkzaam geweest in de Zaanstreek, waarvan de laatste 20 jaar bij
                    Rob Sweers.
                    Nu is het tijd om mijn eigen deur te openen en heet jullie dan ook van harte welkom bij Hairstyle by
                    Tess.</p>
                <router-link to="/contact" class="cta"><span>Afspraak maken</span></router-link>
            </div>

            <div class="model">
                <picture>
                    <source srcset="img/lexi.webp" type="image/webp" loading=lazy>
                    <source srcset="img/lexi.png" type="image/png" loading=lazy>
                    <img src="img/lexi.png" alt="haarmodel" loading=lazy>
                </picture>
            </div>
        </article>

        <article>
            <div class="model">
                <picture>
                    <source srcset="img/model_3.webp" type="image/webp" loading=lazy>
                    <source srcset="img/model_3.png" type="image/png" loading=lazy>
                    <img src="img/model_3.png" alt="haarmodel" loading=lazy>
                </picture>
            </div>

            <div class="text-container">
                <h2>Persoonlijk contact</h2>
                <p>Persoonlijk contact met de klant is voor mij altijd erg belangrijk geweest. Daarnaast is het van belang dat je als
                hairstylist op de hoogte bent van de laatste trends en producten. Dit alles om een klant een prettige en professionele
                haarbeleving te geven, en met een mooi kapsel en een tevreden gevoel de deur uit te laten gaan.</p>
            </div>

        </article>



        <article>
            <div class="text-container">
                <h2>Afspraak maken</h2>
                <p>In mijn salon bied ik je comfort, en alle aandacht die je als klant verdiend. Ik werk uitsluitend op afspraak in Wormer,
                dus neem contact met mij op via mail, whatsapp of telefoon. Tevens kom ik ook op locatie op iedere donderdag in de even
                week. Ook hiervoor kun je een afspraak maken bij mij. Ik verplaats mij in de regio rondom Wormer. Ik kom in heel
                Zaanstad incl. Assendelft, Westzaan en Krommenie, heel Wormerland, Oostzaan, Purmerend en Landsmeer.</p>
                <router-link to="/contact" class="cta"><span>Afspraak maken</span></router-link>
            </div>

            <div class="scissor">
                <picture>
                    <source srcset="img/schaar_tess.webp" type="image/webp" loading=lazy>
                    <source srcset="img/schaar_tess.png" type="image/png" loading=lazy>
                    <img src="img/schaar_tess.png" alt="schaar" loading=lazy>
                </picture>
            </div>
        </article>


    </section>
</template>

<script>
import * as main from '../assets/js/main.js';

export default {
    mounted() {
        const navimg = document.querySelector(".scissor img");
        function scrollScissor() {
            window.onscroll = function () { scrollRotate() };

            function scrollRotate() {

                navimg.style.transform = "rotate(" + (window.pageYOffset / 20) + "deg)"
            }
        }
        scrollScissor()

        main.homeInfo()
        main.scrollTo()
    }
}
</script>

<style lang="scss" scoped>




@import "../assets/scss/_variables.scss";
.map-container{
    position: relative;
    min-height: 40vh;
    display:flex;
    .text-container {
        transform: translateX(16.66667%) !important;
    }
   
}
.home-info {
    article {
        display: flex;
        align-items: center;
        margin-bottom: 15rem;
      
    }
        .text-container {
            max-width: calc(100% / 3);
            transform: translateX(16.66667%);
            &:nth-of-type(even) {
                transform: translateX(-16.66667%);
                @media (min-width: 1600px) {
                        transform: translateX(-40%);
                    }
            }
            p {
                    line-height: 2;
                    margin-bottom: 2rem;
                }
        }

}

.model {
    width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: $bg-gray;
        padding: 6rem 0 0 0;
        min-height: 700px;
        img {
            min-height: 700px;
        }
}
.scissor {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg-gray;
    // padding: 0 0 0 0;
    min-height: 700px;
    picture {
        // display: inline-block;
        img {
                max-height: 500px;
                transform: rotate(80deg);
                @media (min-width: 1600px) {
                        // max-width: 66.66%;
                        height: 300px;
                    }
            }
    }
}
.home-info {
    @media (max-width: 800px) {
        article {
            margin: 0;
            flex-direction: column;

            &:nth-of-type(odd) {
                flex-direction: column-reverse;
            }

            .model {
                width: 100vw;
                min-height: 300px;

                img {
                    height: 300px;
                    min-height: 300px;
                }
            }
        }
        
        .text-container {
            max-width: 100%;
            transform: translateX(0%) translateY(-20%) !important;

        }

        .scissor {
            width: 100vw;
            min-height: 300px;

            img {
                z-index: -1;
                max-height: 120px;
            }
        }
    }
}
</style>
