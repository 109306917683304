<template>
    <section class="price-table">
        <h1>Prijzen</h1>

        <table class="styled-table">
            <thead>
                <tr>
                    <th>Behandeling</th>
                    <th>Prijs incl. BTW</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2">Kinderen</th>
                </tr>
                <tr>
                        <td>Tot en met 12 jaar</td>
                        <td>€ 20,00</td>
                    </tr>
                <tr>
                    <th colspan="2">Heren</th>
                </tr>
                <tr>
                    <td>Heren tondeuse</td>
                    <td>€ 20,00</td>
                </tr>
                <tr>
                    <td>Heren knippen</td>
                    <td>€ 29,00</td>
                </tr>
                <tr>
                    <td>Heren wassen knippen stylen</td>
                    <td>€ 31,00</td>
                </tr>
                <tr>
                    <th colspan="2">Dames</th>
                </tr>
                <tr>
                    <td>Dames wassen fohnen</td>
                    <td>€ 29,00</td>
                </tr>
                <tr>
                    <td>Dames wassen knippen</td>
                    <td>€ 34,00</td>
                </tr>
                <tr>
                    <td>Dames wassen, knippen en drogen</td>
                    <td>€ 40,00</td>
                </tr>
                <tr>
                    <td>Dames wassen, knippen en fohnen</td>
                    <td>€ 46,00</td>
                </tr>
                <tr>
                    <td>Dames wassen, knippen en toeslag lang haar</td>
                    <td>€ 8,00</td>
                </tr>
                <tr>
                    <th colspan="2">Kleuren</th>
                </tr>
                <tr>
                    <td>Kleuren kort of uitgroei</td>
                    <td>€ 42,00</td>
                </tr>
                <tr>
                    <td>Kleuren toeslag lang haar</td>
                    <td>€ 16,00</td>
                </tr>
                <tr>
                    <th colspan="2">Spoeling</th>
                </tr>
                <tr>
                    <td>Spoeling kort</td>
                    <td>€ 32,00</td>
                </tr>
                <tr>
                    <td>Spoeling toeslag lang haar</td>
                    <td>€ 16,00</td>
                </tr>
                <tr>
                    <th colspan="2">Highlights</th>
                </tr>
                <tr>
                    <td>Highlights kam</td>
                    <td>€ 29,00</td>
                </tr>
                <tr>
                    <td>Highlights muts</td>
                    <td>€ 39,00</td>
                </tr>
                <tr>
                    <td>Highlights folie deel</td>
                    <td>€ 54,00</td>
                </tr>
                <tr>
                    <td>Highlights folie helemaal</td>
                    <td>€ 69,00</td>
                </tr>
                <tr>
                    <th colspan="2">Permanent</th>
                </tr>
                <tr>
                    <td>Permanent deel incl. knippen en fohnen</td>
                    <td>€ 77,00</td>
                </tr>
                <tr>
                    <td>Permanent helemaal incl. knippen en fohnen</td>
                    <td>€ 89,00</td>
                </tr>
                <tr>
                    <td>Permanent toeslag lang haar</td>
                    <td>€ 14,00</td>
                </tr>
                <tr>
                    <th colspan="2">Toeslag knippen op locatie</th>
                </tr>
                <tr>
                    <td>Zaanstad, Wormerland</td>
                    <td>€ 5,00</td>
                </tr>
                <tr>
                    <td>Landsmeer, Purmerend en Oostzaan</td>
                    <td>€ 5,00</td>
                </tr>
                <!-- and so on... -->
            </tbody>
        </table>
    </section>
</template>

<script>
import * as main from '../assets/js/main.js';
export default {
    mounted() {
        main.priceTable()
        main.scrollTo()
    }
}
</script>

<style lang="scss" scoped>





@import "../assets/scss/_variables.scss";

.center {
    text-align: center;
}
section {
    min-height: 90vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.styled-table {
    transform: translateX(-15vw);
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
}

.styled-table thead tr {
    background-color: € bg-gray;
    color: € txt-color;
    text-align: left;
    font-family: 'Oswald', sans-serif;
        line-height: 1.1;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: thin solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}
.styled-table tbody th.active-row {
    font-weight: bold;
    color: #009879;
}

@media (max-width: 800px) {
    h1 {
        font-size: € header-sm;
    }
    section {
        flex-direction: column;
    }
    .styled-table {
        transform: translateX(0vw);
        min-width: 80vw;
        margin-bottom: 5rem;
    }
}
</style>