<template>
  <main>
    <Hero />
    <HomeInfo />
  </main>
</template>

<script>
import Hero from '../../components/Hero.vue'
import HomeInfo from '../../components/HomeInfo.vue'
import * as main from '../../assets/js/main.js';

export default {
  name: 'Home',
  components: {
    Hero,
    HomeInfo,
  },
  link: [
    { rel: "preload",as:"image", href: "/path/to/hero-image.webp", type: "image/webp" }
  ]
}
</script>

<style lang="scss">



</style>
