<template>
    <main>
        <PriceTable />
    </main>
</template>

<script>
import PriceTable from '../../components/PriceTable.vue'

export default {
    name: 'Prijzen',
    components: {
        PriceTable
    }
}
</script>

<style>

</style>