<template>
  <header>
    <router-link class="logo" to="/">
      <picture>
        <source srcset="/logo_zwart.webp" type="image/webp">
        <source srcset="/logo_zwart.png" type="image/png">
        <img src="/logo_zwart.png" alt="logo">
      </picture>

    </router-link>

    <div class="toggle">
      <span class="toggle-btn"></span>
    </div>
    <nav class="menu">
      <ul class="menu-list">
        <li class="menu-item">
          <router-link to="/prijslijst" class="menu-link link link--dia"><span>Prijslijst</span></router-link>
        </li>
        <li class="menu-item">
          <router-link to="/about" class="menu-link link link--dia"><span>Over mij</span></router-link>
        </li>
        <li class="menu-item">
          <router-link to="/contact" class="menu-link link link--dia"><span>Afspraak maken</span></router-link>
        </li>

      </ul>
    </nav>
    
  </header>
</template>

<script>

export default {
  mounted() {
    
    // const burger = document.querySelector('.burger i');
    // const nav = document.querySelector('nav ul');
    

    // function toggleNav() {
    //   burger.classList.toggle('fa-bars');
    //   burger.classList.toggle('fa-times');
    //   nav.classList.toggle('nav-active');
    //   nav.classList.toggle('fade');
    // }

    // burger.addEventListener('click', function () {
    //   toggleNav();
    // });

    
    const toggle = document.querySelector(".toggle");
    const toggleBtn = document.querySelector(".toggle-btn");
    const menu = document.querySelector(".menu");
    const menuList = document.querySelector(".menu-list");
    const menuItems = document.querySelectorAll(".menu-item");
    const navLinks = document.querySelectorAll('nav ul a');

    let showMenu = false;

    toggle.addEventListener("click", toggleMenu);

    function toggleMenu() {
      if (!showMenu) {
        toggleBtn.classList.add("open");
        menu.classList.add("open");
        menuList.classList.add("open");
        menuItems.forEach(item => item.classList.add("open"));

        showMenu = true;
      } else {
        toggleBtn.classList.remove("open");
        menu.classList.remove("open");
        menuList.classList.remove("open");
        menuItems.forEach(item => item.classList.remove("open"));

        showMenu = false;
      }
    }

    navLinks.forEach(link => {
      link.addEventListener('click', function () {
        toggleMenu();
        showMenu = false;
      });
    })

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_buttons.scss";

header {
  margin: auto;
  // max-width: 95%;
  padding: 0 8% 0 8%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: .9s;

  img {
    height: auto;
    width: 125px;
    margin-top: 16px;
  }
}

ul {
  display: flex;
  justify-content: space-around;
}

nav a {
  color: $txt-color;
  text-decoration: none;
}

nav li {
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: .9s;
  padding: 0 20px;
  list-style: none;
  margin: 0;
  font-weight: 600;
  font-size: $header-fnt;
  letter-spacing: 0.15em;
}

.burger {
  font-size: 1.2em;
  display: none;
}

.nav-active {
  right: 0;
}

$animationDelay: 1;

@for $i from 1 through 5 {
  nav li:nth-of-type(#{$i}) {
    animation-delay: #{0.3+($i)/30}s;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate(0, -50px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 678px) {

// Trnasition Mixin
@mixin transition-ease {
  transition: all 0.5s ease-in-out;
}

.link::before {
  top: 80%;
}
// Header & Navbar Style
  .toggle {
    z-index: 6 !important;
    position: absolute;
    width: 2rem;
    height: 1.4rem;
    top: 2rem;
    right: 2rem;
    z-index: 2;
    cursor: pointer;
    @include transition-ease;

    &-btn {
      position: absolute;
      top: 0.5rem;
      right: 0;
      width: 1.6rem;
      height: 2px;
      background: $txt-color;
      @include transition-ease;

      &::before {
        content: "";
        position: absolute;
        top: -0.5rem;
        width: 2rem;
        height: 2px;
        background: $txt-color;
        @include transition-ease;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0.5rem;
        width: 1.2rem;
        height: 2px;
        background: $txt-color;
        @include transition-ease;
      }

      &.open {
        transform: rotate(360deg);
        background: transparent;

        &::before {
          transform: rotate(45deg) translate(5px, 8px);
        }

        &::after {
          width: 2rem;
          transform: rotate(-45deg) translate(3px, -6px);
        }
      }
    }
  }

  .menu {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    opacity: 1;
    visibility: hidden;

    &.open {
      visibility: visible;
    }

    &-list {
      margin: 0;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      height: 100vh;
      overflow: hidden;
      list-style: none;
      list-style-type: none;
      padding-right: 1rem;
      background: $bg;
      transform: translateY(-100%);
      @include transition-ease;

      &.open {
        transform: translateY(0);
      }
    }

    &-item {
      transform: translateX(100vw);
      @include transition-ease;

      &.open {
        transform: translateX(0);
      }
    }

    &-link {
      display: inline-block;
      padding: 1rem 0;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: inherit;
      color: $txt-color;
      text-transform: uppercase;
      @include transition-ease;

    }

    @for $i from 1 through 4 {
      .menu-list {
        .menu-item:nth-child(#{$i}) {
          transition-delay: ($i * 0.1s) + 0.15s;
        }
      }
    }
  }

  .logo img{
  height: 60px;
      width: 60px;
      margin-top: 10px;
  }
}


</style>
