<template>
  <section class="hero">
    <div>
      <picture>
        <source srcset="img/tess_2.webp" type="image/webp" rel="preload">
        <source srcset="img/tess_2.png" type="image/png">
        <img src="img/tess_2.png" alt="model">
      </picture>
    </div>

    <div class="background-hero">
      <div class="background-clip"></div>
    </div>

    <div>
      <h1>Over mij</h1>
      <div class="down-arrow"></div>
    </div>
  </section>
</template>

<script>
import * as main from '../assets/js/main.js';
export default {
  mounted() {
    main.hero()
  }
}
</script>

<style lang="scss" scoped>





@import "../assets/scss/_variables.scss";

.hero {
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10rem;
@media (max-width: 1200px) {
    flex-direction: column-reverse;

    @media (max-width: 800px) {
      padding-bottom: 5rem;
    }
  }
  div {
    img {
      min-height: 90vh;
      max-width: auto;
      transform: scaleX(-1)
    }
        @media (max-width: 1600px) {
          img {
            margin-top: 20vh;
            min-height: 70vh;
          }
        }
    
        @media (max-width: 1200px) {
          img {
            margin-top: -40vh;
            margin-left: 10vw;
            min-height: 50vh;
          }
        }
    
        @media (max-width: 800px) {
          img {
            height: 30vh;
          }
        }
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   z-index: 0;
  //   bottom: 0;
  //   left: 0;
  //   pointer-events: none;
  //   background-image: linear-gradient(to bottom,
  //       rgba(255, 255, 255, 0),
  //       rgba(255, 255, 255, 1) 90%);
  //   width: 100%;
  //   height: 4em;
  //   margin-bottom: -2px;
  //    @media (max-width: 1200px) {
  //       visibility: hidden;
  //     }
  // }

}
.hero {
  h1,a, .down-arrow{
    opacity: 0;
  }
}
.background-hero {
  background: $bg-gray;
  position: absolute;
  width: 100vw;
  height: 90vh;
  left: 0;
  z-index: -1;

@media (max-width: 1200px) {
    visibility: hidden;
  }
  .background-clip {
    transform: scaleX(-1);
    background: $bg;
    clip-path: ellipse(100% 100% at 0% 0%);
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@media (max-width: 600px) {
  h1 {
    margin-top: 10vh;
    text-align: right;
  }

  p {
    font-size: 24px;
    max-width: 100%;
  }

  .down-arrow {
    margin-left: auto;
    margin-right: 14px;
  }
}
</style>
