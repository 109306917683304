<template>
    <MainHeader />
    <main class="404">
        <h1>404</h1>
        <p>Page not found</p>
        <NuxtLink @click="goBack()" class="cta">Ga terug</NuxtLink>
    </main>
    <Footer />
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-size: 64px;
    }
}

a {
    cursor: pointer;
}
</style>
