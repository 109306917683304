import * as Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Contact from './pages/Contact/Contact'
import Prijzen from './pages/Prijzen/Prijzen'
import PageNotFound from './pages/404'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";

const routes = [{
        path: '/',
        component: Home,
        meta: {
            transition: 'fade',
            title: 'Hairstyle By Tess | Home',
            metaTags: [{
                    name: 'description',
                    content: 'Kapsalon Hairstyle by tess, boek nu een afspraak. Uitsluitend telefonisch op afspraak of via mail. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                },
                {
                    property: 'og:description',
                    content: 'Kapsalon Hairstyle by tess, boek nu een afspraak. Uitsluitend telefonisch op afspraak of via mail. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                }
            ]
        },
    },
    {
        path: '/about',
        component: About,
        meta: {
            transition: 'fade',
            title: 'Hairstyle By Tess | Over mij',
            metaTags: [{
                    name: 'description',
                    content: 'Kapsalon Hairstyle by tess, boek nu een afspraak. Meer over wie ik ben en wat ik doe. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                },
                {
                    property: 'og:description',
                    content: 'Kapsalon Hairstyle by tess, boek nu een afspraak. Meer over wie ik ben en wat ik doe. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                }
            ]
        },
    },
    {
        path: '/contact',
        component: Contact,
        meta: {
            transition: 'fade',
            title: 'Hairstyle By Tess | Afspraak maken',
            metaTags: [{
                    name: 'description',
                    content: 'Kapsalon Hairstyle by tess, boek nu een afspraak. Uitsluitend telefonisch op afspraak of via mail of neem contact met mij op voor meer informatie. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                },
                {
                    property: 'og:description',
                    content: 'Kapsalon Hairstyle by tess, boek nu een afspraak. Uitsluitend telefonisch op afspraak of via mail of neem contact met mij op voor meer informatie. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                }
            ]
        },
    },
    {
        path: '/prijslijst',
        component: Prijzen,
        meta: {
            transition: 'fade',
            title: 'Hairstyle By Tess | Prijslijst',
            metaTags: [{
                    name: 'description',
                    content: 'Kapsalon Hairstyle by tess, bekijk de prijzen die ik hanteer. Voor meer informatie neem contact met mij op. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                },
                {
                    property: 'og:description',
                    content: 'Kapsalon Hairstyle by tess, bekijk de prijzen die ik hanteer. Voor meer informatie neem contact met mij op. Openingstijden: maandag t/m vrijdag van 9:00 tot 17:00'
                }
            ]
        },
    },
    {
        path: '/kyle',
        beforeEnter(to, from, next) {
            window.location.href = "https://kyle-bot.com";
        }
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    },
    { path: '/', redirect: '/index', meta: { transition: 'fade' }, }
]

const gtag = {

}

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
})
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});
Vue.createApp(App).use(router).use(VueGtag, {
    config: { id: "UA-224586743-1" }
}).mount('#app');