<template>
    <div id="map" ref="map"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from "mapbox-gl";

export default {
    data() {
        return {
            accessToken: 'pk.eyJ1Ijoia3lsZS1ib3QiLCJhIjoiY2szNGQxeHVpMDA2eTNucG42dXRlbGN2OSJ9.xRLcc1Ki130PB1uaL9dKMQ',
        };
    },
    mounted() {
        mapboxgl.accessToken = this.accessToken;
        
        const map = new mapboxgl.Map({
            container: 'map', 
            style: 'mapbox://styles/mapbox/light-v10',
            center: [4.8030763, 52.4994039],
            zoom: 15 ,
            
        });
        map.dragPan.disable()
    },

};
</script>

<style lang="scss" scoped>




.mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

 #map, .mapboxgl-canvas {
     width: 800px;
     height: 700px;

     @media (min-width: 1600px) {
         height: 800px;
         width: 1100px;
     }
         @media (max-width: 800px) {
             height: 400px;
             width: 100vw;
         }
 }

 

</style>